/* eslint-disable indent */
import React from 'react';
import {IReactComponent} from 'mobx-react';
import {action, computed, observable} from 'mobx';
import {
    AlignEnum,
    DashboardSectionTypes,
    ILeftBarItemData,
    ILeftBarSection,
    LeftBarControllerBase,
    LeftBarItemTypesEnum,
    LeftBarSection
} from '../../../layouts/main-layout/left-bar-base/controller';
import {ITranslate} from '../../../services/LocalizationService';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {
    InternalSessionType,
    IStartWithModesController,
    IStartWithModesSession,
    MeetingModes,
    StartWithModesController
} from '../../../components/start-with-modes/controller';
import {getIconNameByMode} from '../../../components/start-with-modes/component';
import {MeetingMode, ModePriority} from '@techsee/techsee-common/lib/constants/room.constants';
import {BorderRoundedIconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/border-rounded-icon';
import {IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import classNames from 'classnames';
import get from 'lodash/get';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {ICountryInfo} from '@techsee/techsee-ui-common/lib/_contracts/CountryInfo';
import {GenericHeader} from '../../../components/common-forms/genericHeader';

import {ObserverModal} from './observerModal';
import {AppLauncherModal} from './appLauncherModal';
// @ts-ignore
import {isModeSupportNativeVideoSharing} from '@techsee/techsee-common/lib/utils';
import {accountTypes} from '@techsee/techsee-common/lib/constants/account.constants';

export interface LeftBarConstructorOptions {
    isDeviceClassificationEnabled: boolean;
    desktopShareEnabled: boolean;
    enableObservation: boolean;
}

export interface LeftBarGeneralOptions {
    clientType?: PlatformType;
    accountType?: accountTypes;
    isClientVisible?: boolean;
    patterns?: {email: RegExp; phone: RegExp};
    usingApplication?: boolean;
    isObserve?: boolean;
    countryList?: ICountryInfo[];
    countriesInfoList?: ICountryInfo[];
}

export interface IObservableDetails {
    connected: boolean;
    firstName: string;
    lastName: string;
}

export class DashboardLeftBarController extends LeftBarControllerBase {
    @observable _leftBarSections: ILeftBarSection[] = [];

    @observable private _currentMode: MeetingMode | undefined = undefined;

    @observable private _displayMultiModes: boolean = false;

    @observable private _isSelfServiceSummaryReady: boolean = false;

    @observable observerDetails: IObservableDetails = {
        connected: false,
        firstName: '',
        lastName: ''
    };

    @observable private _options: LeftBarGeneralOptions = {};

    _environmentService: ITsEnvironmentDetect;

    private _switchModes: (mode: MeetingModes) => void = () => ({});

    private readonly _openHelp: () => void;

    private readonly _openDeviceClassification: () => void;

    private readonly _openSelfServiceSummary: () => void;

    private readonly _onItemClicked: (item: {}, currentMode: MeetingMode | undefined) => void;

    private readonly _isDeviceClassificationEnabled: boolean = false;

    private readonly _enableObservation: boolean = false;

    private readonly _startWithModesController: IStartWithModesController;

    private readonly _settings: any;

    private _onSubmitObserver: any;

    private _onSubmitAppLauncher: any;

    private readonly _isAppLauncherEnabled: boolean;

    constructor(
        translate: ITranslate,
        environmentService: ITsEnvironmentDetect,
        options: LeftBarConstructorOptions,
        openHelp: () => void,
        openDeviceClassification: () => void,
        openSelfServiceSummary: () => void,
        openModeSwitchConfirmationModal: (item: {}, currentMode: MeetingMode | undefined) => void,
        onSubmitObserver: any,
        onSubmitAppLauncher: any
    ) {
        super(translate);

        this._environmentService = environmentService;
        this._settings = options;
        this._openHelp = openHelp;
        this._openDeviceClassification = openDeviceClassification;
        this._openSelfServiceSummary = openSelfServiceSummary;
        this._onItemClicked = openModeSwitchConfirmationModal;
        this._isDeviceClassificationEnabled = this._settings.isDeviceClassificationEnabled;
        this._enableObservation = this._settings.enableObservation;
        this._startWithModesController = new StartWithModesController(this.translate, () => {});
        this._onSubmitObserver = onSubmitObserver;
        this._onSubmitAppLauncher = onSubmitAppLauncher;
        this._isAppLauncherEnabled =
            this._settings.appLauncher &&
            this._settings.appLauncher.dashboard &&
            this._settings.appLauncher.dashboard.enabled;

        this._startWithModesController.setConfig({
            desktopShareEnabled: this._settings.desktopShareEnabled
        });

        this._leftBarSections = this.getLeftBarItems();
    }

    @action
    setObservableDetails(data: IObservableDetails) {
        this.observerDetails = data;
    }

    @computed
    get startWithModes() {
        return this.startWithModesController.startWithModes;
    }

    @computed
    get internalMode() {
        if (this._options.clientType === PlatformType.desktop_web) {
            return InternalSessionType.desktopSharing;
        }

        return get(this._startWithModesController.currentMode, 'internalMode');
    }

    @action
    setSelfServiceSummaryButton() {
        this._isSelfServiceSummaryReady = true;
    }

    @action
    setAvailableMeetingModes(startWithModes: IStartWithModesSession[]) {
        this._displayMultiModes = true;
        this._startWithModesController.setAvailableMeetingModes(startWithModes);
        this._leftBarSections = this.getLeftBarItems();
    }

    setCurrentMeetingMode(meetingMode: MeetingMode) {
        this._currentMode = meetingMode;
        this._startWithModesController.setSelectedMeetingMode(meetingMode);
    }

    @computed
    get startWithModesController() {
        return this._startWithModesController;
    }

    setSwitchModes(switchModes: (mode: MeetingModes) => void) {
        this._switchModes = switchModes;
    }

    @action
    setDeviceClassificationDisabled(value: boolean) {
        if (this._isDeviceClassificationEnabled) {
            this.executeItem(
                () => true,
                (section) => section.sectionType === DashboardSectionTypes.AGENT_ASSIST,
                (item) => {
                    item.isDisabled = !value;
                }
            );
        }
    }

    @action
    setIsObserverConnected(value: boolean) {
        this.executeItem(
            () => true,
            (section) => section.sectionType === DashboardSectionTypes.OBSERVER,
            (item) => {
                item.isObserverConnected = value;
            }
        );
    }

    @action
    closeObserverInvite() {
        this.executeItem(
            () => true,
            (section) => section.sectionType === DashboardSectionTypes.OBSERVER,
            (item) => {
                item.isSelected = false;
            }
        );
    }

    @action
    disableEnableModes(value: boolean) {
        this.executeItem(
            (item) => item.isDisabled === value,
            (section) => section.sectionType === DashboardSectionTypes.MODES,
            (item) => {
                item.isDisabled = !value;
            }
        );
    }

    @action
    selectAgentAssist() {
        this.executeItem(
            () => true,
            (section) => section.sectionType === DashboardSectionTypes.AGENT_ASSIST,
            (item) => {
                item.isSelected = true;
                item.isDisabled = true;
            }
        );
    }

    @action
    cleanSelectedStates() {
        if (!this._isInsideContent) {
            this.executeItem(
                (item) => item.isSelected,
                (section) => section.sectionType === DashboardSectionTypes.MODES,
                (item) => {
                    item.isSelected = false;
                }
            );
        }
    }

    @action
    clickToSwitchMode(item: IStartWithModesSession) {
        this._startWithModesController.setSelectedMeetingMode(item);
        this._switchModes(item.mode);
        this._leftBarSections = this.getLeftBarItems();
    }

    @action
    getModesSections(): LeftBarSection | null {
        if (!this._currentMode) {
            return null;
        }

        const sharingModes = [
            MeetingMode.appSharing,
            MeetingMode.screen,
            MeetingMode.videoApplication
        ] as MeetingModes[];
        const isDesktopSharingInitialMode = this.internalMode === InternalSessionType.desktopSharing;
        const isCurrentModeSdkSharingMode =
            !isDesktopSharingInitialMode && sharingModes.indexOf(this._currentMode!) > -1;
        const isMoreButton = this._startWithModesController.startWithModes.length > 3 && !isDesktopSharingInitialMode;

        const res = new LeftBarSection(AlignEnum.TOP, false, [], DashboardSectionTypes.MODES);
        const content: IReactComponent[] = [];

        this._startWithModesController.startWithModes
            .filter((item) => {
                //We filter the list of available modes to display on the left bar.
                //If mode is FACE_MEET then drop it:
                if (item.mode === MeetingMode.faceMeet) {
                    return false;
                }

                //Is current item in the filter is the DS mode.
                const isDesktopSharingMode = item.internalMode === InternalSessionType.desktopSharing;
                //Is user connected via the sdk webview | this._options.usingApplication
                //or is the current mode a sharing mode but not DS | isCurrentModeSdkSharingMode
                const isUsingApplication = this._options.usingApplication || isCurrentModeSdkSharingMode;

                // If we've started the session in desktop sharing then show only desktop sharing mode
                if (isDesktopSharingInitialMode && isDesktopSharingMode) {
                    return true;
                }

                //If we are connected via the sdk -> show all modes except desktopSharing mode in the leftBar
                //If we are NOT connected via sdk:
                //In case we did NOT start with desktopSharing mode then show all non screenSharing modes in the leftBar
                if (isUsingApplication && isDesktopSharingMode) {
                    return false;
                }

                return true;
            })
            .forEach((item: IStartWithModesSession) => {
                const icon = item.icon || getIconNameByMode(item.mode) || '';
                const uid = icon;
                const isDisabled =
                    item.internalMode === InternalSessionType.desktopSharing ||
                    this._options.isClientVisible === false ||
                    !!this._options.isObserve;

                // Activated mode
                if (item.internalMode === this.internalMode) {
                    res.leftBarItems.push({
                        uid,
                        icon: () => icon,
                        isHovered: false,
                        isSelected: false,
                        isActive: true,
                        isDisabled: isDisabled,
                        tooltip: () => item.title || this.translate('REACT.INVITE.START_WITH_MODES.' + item.mode),
                        onItemClicked: () => !isDisabled && this._onItemClicked(item, this._currentMode)
                    });
                }

                if (
                    item.priority === ModePriority.PRIMARY &&
                    item.mode !== this._startWithModesController.defaultMode &&
                    item.internalMode !== this.internalMode
                ) {
                    res.leftBarItems.push({
                        uid,
                        icon: () => icon,
                        isHovered: false,
                        isSelected: false,
                        isActive: false,
                        isDisabled: isDisabled,
                        tooltip: () => item.title || this.translate('REACT.INVITE.START_WITH_MODES.' + item.mode),
                        onItemClicked: () => !isDisabled && this._onItemClicked(item, this._currentMode)
                    });
                }

                if (item.priority === ModePriority.SECONDARY && item.mode !== this._currentMode) {
                    if (isMoreButton) {
                        const classString =
                            'button-secondary-container-left-bar' + (isDisabled ? ' disabled-button' : '');

                        content.push(() => (
                            <div
                                className={classString}
                                onClick={() => !isDisabled && this._onItemClicked(item, this._currentMode)}>
                                <BorderRoundedIconButton
                                    className={'button-secondary-style'}
                                    iconName={icon}
                                    noBorder={true}
                                    reverseColor={true}
                                    sizeName={IconSizes.Large}
                                    colorName={IconColors.Blue}
                                />
                                <SimpleLabel className={'label-center'}>
                                    {item.title || this.translate('REACT.INVITE.START_WITH_MODES.' + item.mode)}
                                </SimpleLabel>
                            </div>
                        ));
                    } else {
                        res.leftBarItems.push({
                            uid,
                            icon: () => icon,
                            isHovered: false,
                            isSelected: false,
                            isActive: false,
                            isDisabled: isDisabled,
                            tooltip: () => item.title || this.translate('REACT.INVITE.START_WITH_MODES.' + item.mode),
                            onItemClicked: () => !isDisabled && this._onItemClicked(item, this._currentMode)
                        });
                    }
                }
            });

        if (isMoreButton && content.length > 0) {
            res.leftBarItems.push({
                uid: 'more',
                icon: () => 'more',
                isHovered: false,
                isSelected: false,
                isActive: false,
                isDisabled: false,
                tooltip: () => this.translate('REACT.INVITE.START_WITH_MODES.MORE'),
                content: () => (
                    <div
                        className={classNames('start-with-modes-secondary-buttons', 'use-common-styles', {
                            'one-mode': content.length === 1
                        })}>
                        {content.map((Item, indexSecondary) => (
                            <Item key={indexSecondary} />
                        ))}
                    </div>
                ),
                onItemClicked: () => this._startWithModesController.showSecondary()
            });
        }

        return res;
    }

    @action
    getLeftBarItems() {
        let modesSection = null;
        const isObserver = !!this._options.isObserve;

        if (this._displayMultiModes) {
            modesSection = this.getModesSections();
        }

        const profileButton = {
            uid: 'profile',
            icon: () => 'profile',
            isDisabled: true,
            isActive: false,
            isSelected: false,
            isHovered: false,
            onItemClicked: function () {},
            tooltip: () => this.translate('REACT.DASHBOARD.VIEW.TOOLTIP.SETTINGS')
        };

        // Add separator only if there's one or more items after the observer (which means agent assist or app launcher)
        const observerSeparatorNeeded = this._isDeviceClassificationEnabled || this._isAppLauncherEnabled;
        const observer = new LeftBarSection(
            AlignEnum.TOP,
            observerSeparatorNeeded,
            [
                {
                    uid: 'observer',
                    icon: () => 'observe',
                    isActive: false,
                    isSelected: false,
                    onItemClicked: function () {},
                    isObserverConnected: this.observerDetails.connected,
                    isDisabled: isObserver,
                    isHovered: false,
                    content: () => (
                        <ObserverModal
                            accountSettings={this._settings}
                            patterns={this._options.patterns}
                            observerDetails={this.observerDetails}
                            countryList={this._options.countryList}
                            countriesInfoList={this._options.countriesInfoList}
                            translate={this.translate}
                            formErrorText={'INVITE.VIEW.HISTORY_SEARCH.NO_RESULTS'}
                            labelText={'REACT.LEFTBAR.POPOVER.SESSION_OBSERVER_LABEL'}
                            buttonText={'REACT.LEFTBAR.POPOVER.SESSION_OBSERVER_SUBMIT'}
                            onSubmitObserver={this._onSubmitObserver}
                        />
                    ),
                    tooltip: () => this.translate('REACT.LEFTBAR.TOOLTIP.SESSION_OBSERVER')
                }
            ],
            DashboardSectionTypes.OBSERVER
        );

        const appLauncher = new LeftBarSection(
            AlignEnum.TOP,
            false,
            [
                {
                    uid: 'app-launcher',
                    icon: () => 'quick-launch',
                    isActive: false,
                    isSelected: false,
                    onItemClicked: function () {},
                    isDisabled: isObserver,
                    isHovered: false,
                    content: () => (
                        <AppLauncherModal
                            accountSettings={this._settings}
                            translate={this.translate}
                            formErrorText={'REACT.LEFTBAR.POPOVER.APP_LAUNCHER_NO_DEST'}
                            labelText={'REACT.LEFTBAR.POPOVER.APP_LAUNCHER_LABEL'}
                            buttonText={'REACT.LEFTBAR.POPOVER.APP_LAUNCHER_SUBMIT'}
                            onSubmitAppLauncher={this._onSubmitAppLauncher}
                        />
                    ),
                    tooltip: () => this.translate('REACT.LEFTBAR.TOOLTIP.APP_LAUNCHER')
                }
            ],
            DashboardSectionTypes.APP_LAUNCHER
        );

        const buttonGroup = [];

        buttonGroup.push(profileButton);

        const helpSection = new LeftBarSection(AlignEnum.BOTTOM, false, buttonGroup, DashboardSectionTypes.HELP);

        if (modesSection && this._isDeviceClassificationEnabled) {
            modesSection?.setDisplaySeparator(true);
        }

        // Linter doesn't allow setting null to ILeftBarSection object
        const items = [];

        items[LeftBarItemTypesEnum.modesSection] = modesSection;
        items[LeftBarItemTypesEnum.observer] =
            modesSection && this._enableObservation && !isModeSupportNativeVideoSharing(this._currentMode)
                ? observer
                : null;
        items[LeftBarItemTypesEnum.agentAssist] = this.getAgentAssistItem(isObserver);
        items[LeftBarItemTypesEnum.appLauncher] = this._isAppLauncherEnabled ? appLauncher : null;
        items[LeftBarItemTypesEnum.helpSection] = helpSection;

        const leftBarItems: ILeftBarSection[] = [];

        items.forEach((item) => {
            // Must do this explicitly because of the linter
            if (item !== null) {
                leftBarItems.push(item);
            }
        });

        return leftBarItems;
    }

    private getAgentAssistItem(isObserver: boolean) {
        if (this._isDeviceClassificationEnabled) {
            const agentAssistState = this.getAgentAssistState();

            if (agentAssistState && !this._isSelfServiceSummaryReady) {
                return agentAssistState;
            }

            const params = this.getAgentAssistParams(isObserver);
            const agentAssistSeparatorNeeded = this._isAppLauncherEnabled;

            return new LeftBarSection(
                AlignEnum.TOP,
                agentAssistSeparatorNeeded,
                params,
                DashboardSectionTypes.AGENT_ASSIST
            );
        }

        return null;
    }

    private getAgentAssistParams(isObserver: boolean) {
        const params = [
            {
                uid: 'agent-assist',
                icon: () => 'agent-assist',
                isActive: false,
                isSelected: false,
                onItemClicked: this._openDeviceClassification,
                isDisabled: isObserver,
                isHovered: false,
                tooltip: () => this.translate('REACT.DASHBOARD.VIEW.TOOLTIP.AGENT_ASSIST')
            }
        ];

        if (this._isSelfServiceSummaryReady) {
            params.push({
                uid: 'warm-transfer',
                icon: () => 'warm-transfer',
                isActive: false,
                isSelected: false,
                onItemClicked: this._openSelfServiceSummary,
                isDisabled: isObserver,
                isHovered: false,
                tooltip: () => this.translate('REACT.DASHBOARD.VIEW.TOOLTIP.SELF_SERVICE')
            });
        }

        return params;
    }

    private getAgentAssistState(): ILeftBarSection | undefined {
        return this._leftBarSections.filter((section) => section.sectionType === DashboardSectionTypes.AGENT_ASSIST)[0];
    }

    private _toggleModalsIfNeeded(sectionType: DashboardSectionTypes) {
        let foundSection = null;

        switch (sectionType) {
            case DashboardSectionTypes.OBSERVER:
                foundSection = this._leftBarSections.find(
                    (section) => section.sectionType === DashboardSectionTypes.APP_LAUNCHER
                );
                if (foundSection) {
                    this.cleanSelectedByItem(foundSection.leftBarItems[0]);
                }
                break;

            case DashboardSectionTypes.APP_LAUNCHER:
                foundSection = this._leftBarSections.find(
                    (section) => section.sectionType === DashboardSectionTypes.OBSERVER
                );
                if (foundSection) {
                    this.cleanSelectedByItem(foundSection.leftBarItems[0]);
                }
                break;

            default:
                break;
        }
    }

    @action
    onLeftBarItemClicked(item: ILeftBarItemData, sectionType: DashboardSectionTypes): void {
        if (item.isDisabled) {
            return;
        }

        this._toggleModalsIfNeeded(sectionType);

        if (item.content) {
            item.isSelected = !item.isSelected;
        } else {
            item.onItemClicked();

            if (sectionType === DashboardSectionTypes.MODES) {
                item.isActive = true;
            } else if (sectionType === DashboardSectionTypes.AGENT_ASSIST) {
                item.isSelected = !item.isSelected;
            }
        }
    }

    @action
    setOptions(options: Partial<LeftBarGeneralOptions>) {
        this._options = {
            ...this._options,
            ...options
        };
    }

    @action
    getHeaderComponent(itemData: ILeftBarItemData, sectionType: DashboardSectionTypes) {
        switch (sectionType) {
            case DashboardSectionTypes.OBSERVER:
                return (
                    <GenericHeader
                        onClose={() => this.cleanSelectedByItem(itemData)}
                        className={classNames('header-left-bar-observer', {
                            'header-left-bar-observer-connected': itemData.isObserverConnected
                        })}
                        shouldShowCloseIcon={true}
                        labelText={this.translate('REACT.LEFTBAR.POPOVER.SESSION_OBSERVER_HEADER')}
                    />
                );
            case DashboardSectionTypes.APP_LAUNCHER:
                return (
                    <GenericHeader
                        onClose={() => this.cleanSelectedByItem(itemData)}
                        className={classNames('header-left-bar-app-launcher')}
                        shouldShowCloseIcon={true}
                        labelText={this.translate('REACT.LEFTBAR.POPOVER.APP_LAUNCHER_HEADER')}
                    />
                );
            default:
                return <React.Fragment />;
        }
    }
}
